import React from "react";
import HeroFull02 from "../components/sections/HeroFull02";
import { Helmet } from "react-helmet";
import ScrollReveal from "utils/ScrollReveal";

import Layout from "components/Layout";

class ThankYou extends React.Component {
  componentDidMount() {
    if (typeof document !== `undefined`) {
      document.body.classList.add("is-loaded");
    }

    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }
  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Layout>
            <Helmet>
              <title>
                Immersion XP | Learn a language through fun, affordable,
                bite-size immersion experiences
              </title>
              <link rel="shortcut icon" href="/images/favicon.png" />
              <meta property="og:image" content="/images/og-image.png" />
            </Helmet>
            <React.Fragment>
              <HeroFull02 />
            </React.Fragment>
          </Layout>
        )}
      />
    );
  }
}

export default ThankYou;
