import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class HeroFull extends React.Component {
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <div className="container-xs">
                <SectionHeader
                  data={{
                    title: "Thank you! Yipee!",
                    paragraph:
                      "We'll let you know the second there are experiences available in your area! In the meantime, check out this short Ted Talk on the benefits of speaking multiple languages. We got this!",
                  }}
                  className="center-content"
                />
                <Button tag="a" color="primary" href="/">
                  Back to home
                </Button>
              </div>
            </div>
            <div
              className="hero-figure reveal-from-bottom"
              data-reveal-delay="150"
            >
              <a
                data-video="https://www.youtube.com/embed/MMmOLN5zBLY"
                href="#0"
                aria-controls="video-modal"
                onClick={this.openModal}
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/bilingual-benefits.png")}
                  alt="Video"
                  width={712}
                  height={400}
                />
              </a>
            </div>
            <Modal
              id="video-modal"
              show={this.state.videoModalActive}
              handleClose={this.closeModal}
              video="https://www.youtube.com/embed/MMmOLN5zBLY"
              videoTag="iframe"
            />
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
